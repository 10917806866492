@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.App {
  text-align: center;
}
body{
  font-family: 'Inter', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo{
  height: 50px;
  width: auto;
  display: inline-block;
}
#buleD{
  height: 300px;
  width: auto;
  display: inline-block;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navLink {
  color: rgb(90, 89, 89);
  margin-left: 15px;
  text-decoration: none;

}

.active {
  color: blue;
  margin-left: 15px;
  text-decoration: none;
}
.SerchContainer {
  text-align: center;
  justify-content: center;
  background-color: #635cdb;
  padding: 80px 0px;
  font-family: 'Inter', sans-serif;
}
#certificate-search{
  padding: 10px 10px;
  margin: 60px 0px;
  width: 30%;
  border-width: 0px;
  outline: none;
  border-radius: 2px 0px 0px 2px;
}

#btn{
  padding: 10px 60px;
  margin: 60px 0px;

  background-color: black;
  color: white;
  border-width: 0px;
}
#btn:hover{
  padding: 10px 60px;
  margin: 60px 0px;

  background-color: white;
  color: black;
  border-color: white;
}
.abouUs{
  font-family: 'Inter', sans-serif;

}
.services{
  background: linear-gradient(#635cdb 60%, transparent 40%) no-repeat;
  align-items: center;
  text-align: center;
  padding: 80px 0px 0px 0px;

}
.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
 
  
  padding: 50px 0px 0px 0px;

}
.service-card {
  width: 350px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 3px lightgray;

  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  text-align: center;
  margin: 20px 20px;
}
.service-icon {
  width: 80px;
  height: 80px;
 
  display: inline-block;
  margin: 50px 0 10px 0;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
  
}
.service-icon i {
  font-size: 40px;
  color: white;
  line-height: 80px;
}
.service-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.service-desc {
  font-size: 16px;
  margin: 0 25px 20px 25px;
  text-align: justify;
}


.button-group {
  display: flex;
  gap: 1rem;
}

.diamond-button {
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.2rem;
  padding: 1rem 2rem;
}

.ruby-button {
  background-color: #ff4d4d;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.2rem;
  padding: 1rem 2rem;
}

.activebtn {
  background-color: #333;
  color: #fff;
}




header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.aboutBtn {
  border-radius: 50px;
  border: 2px solid blue;
  color: blue;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;

}

.aboutBtn:hover {
  background-color: blue;
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






.site-footer
{
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}


.footerRow{
  display: flex;
  flex-direction: row;
}
.location{
  flex: 5;
}
.links{
  flex: 3;
  margin: 0 20px;
}


.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}















/* login screen */



#loginform{
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 100px auto;
  background-color: #FFFFFF;
  border-radius: 25px;
}

#headerTitle{
  text-align: center;
  font-family: 'open sans', sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.row{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
  
}

.row input{
  width: 80%;
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}

.row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.row label{
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: rgba(187,187,187,0.9);
}

.row button{
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  background: rgb(34,193,195);
  background: linear-gradient(90deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}

.row button:hover{
  opacity: 0.8;
}

#button{
  padding-bottom: 1.5rem;
}

#facebookIcon{
  background-image:      url("https://image.flaticon.com/icons/svg/145/145802.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#twitterIcon{
  background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#googleIcon{
  background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#iconGroup{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

#iconGroup a{
  margin: 0 0.6rem;
}

#alternativeLogin {
   text-align: center;
   padding-top: 2rem;
   margin-top: 1.5rem;
}








/* Form */



.form-group {

  margin: 20px 10px;
}

.form-group input{
  margin: 0px 20px;
}